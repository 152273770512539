import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';
import NotFound from '@/components/error/NotFound';
import { useRouter } from 'next/router';

export default function Custom404() {
  const router = useRouter();
  return router.asPath && router.asPath.startsWith('/search') ? (
    <NotFound
      keyword={decodeURIComponent(
        router.asPath.split('/')[router.asPath.split('/').length - 1],
      )}
    />
  ) : (
    <NotFound />
  );
}

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'common',
        'refund',
        'profile',
      ])),
    },
  };
}
